<template>
    <card class="wrapper">
        <AdminTitle title="账期" size="20px"> </AdminTitle>
        <card class="wrapper special-card">
            <el-checkbox
                disabled
                v-for="item in orderBillList"
                :key="item.billNum"
                :label="item"
                v-model="item.isPrepay"
                >第{{ item.billNum }}期（￥：{{ item.billPrice }}）</el-checkbox
            >
        </card>
    </card>
</template>

<script>
export default {
    props: {
        detailInfo: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        orderBillList() {
            return this.detailInfo.orderBillList;
        },
    },
    data() {
        return {};
    },
    methods: {},
    beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
    width: 100%;
    border-color: transparent;
    margin-top: 20px;
    border-radius: 16px;
    color: #222222;
    .el-checkbox__input.is-disabled + span.el-checkbox__label {
        color: #222222;
    }
    .first-price {
        font-weight: 600;
    }
    .col2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .el-checkbox {
            min-width: 250px;
        }
    }
    .el-button {
        border-radius: 15px;
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        border-color: #222;
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
        border-color: #222;
    }
    .el-checkbox__input.is-disabled .el-checkbox__inner {
        border-color: #222;
    }
}
.special-card {
    background: #f7f8fa;
    line-height: 35px;
    color: #222222;
}
</style>
