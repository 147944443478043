<template>
  <card class="wrapper" v-if="orderBillList && orderBillList.length > 0">
    <AdminTitle title="账期" size="20px"/>
    <card class="wrapper special-card">
      <div class="item">
        <el-checkbox
            disabled
            v-for="item in orderBillList"
            :key="item.billNum"
            :label="item"
            v-model="item.isPrepay"
        >第{{ item.billNum }}期（￥：{{ item.billPrice }}）</el-checkbox>
      </div>
    </card>
  </card>
</template>

<script>
export default {
  name: 'Period',
  props: ['orderBillList'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  border-color: transparent;
  margin-top: 20px;
  border-radius: 16px;
  color: #222222;
  .el-checkbox__input.is-disabled + span.el-checkbox__label {
    color: #222222;
  }
  .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    border-color: #222;
  }
  .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #222;
  }
  .el-checkbox__input.is-disabled .el-checkbox__inner {
    border-color: #222;
  }
  .first-price {
    font-weight: 600;
  }
  .col2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .el-checkbox {
      min-width: 250px;
    }
  }
  .el-button {
    border-radius: 15px;
  }
}
.special-card {
  background: #f7f8fa;
  line-height: 35px;
  color: #222222;
}
</style>
