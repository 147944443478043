<template>
    <card class="base-info-new">
        <div class="base-info-top">
            <div class="id-card-pic">
                <el-image
                    style="width: 100px; height: 100px; margin-right: 10px"
                    :src="member ? member.identityFrontImageUrl : ''"
                    :preview-src-list="member ? [member.identityFrontImageUrl] : []"
                >
                </el-image>
                <el-image
                    style="width: 100px; height: 100px"
                    :src="member ? member.identityBackImageUrl : ''"
                    :preview-src-list="member ? [member.identityBackImageUrl] : []"
                >
                </el-image>
            </div>
            <div class="person-info">
                <div>
                    <span class="name fw700">{{ member ? member.memberName : '' }}</span>
                    <el-tag type="success">年龄：{{ member ? member.age : '' }}</el-tag>
                    <el-tag type="success">性别：{{ member ? member.genderName : '' }}</el-tag>
                    <span v-for="(item, i) in detailInfo.rosterLevelList" :key="i">
                        <span class="tag-ku t3" v-if="item == 1" style="margin: 2px">白名单</span>
                        <span class="tag-ku t1" v-if="item == 2" style="margin: 2px"
                            ><i class="el-icon-warning"></i>黑名单</span
                        >
                        <span class="tag-ku t2" v-if="item == 3" style="margin: 2px"
                            ><i class="el-icon-warning"></i>灰名单</span
                        >
                    </span>
                </div>
                <div>
                    <span class="mr50">
                        <span class="label">手机号：</span>
                        <span class="fw700">{{ member ? member.mobile : '' }}</span>
                    </span>
                    <span>
                        <span class="label">身份证号：</span>
                        <span class="fw700">{{ member ? member.identityNoPlain : '' }}</span>
                    </span>
                </div>
                <div>
                    <span>
                        <span class="label">户籍所在地：</span>
                        <span class="fw700">{{ member ? member.identityAddress : '' }}</span>
                    </span>
                </div>
                <div class="history-tag">
                    <el-tag type="success"
                        >历史支付单数：{{ member ? member.dealOrderNum : '' }}</el-tag
                    >
                    <!-- <el-tag type="success" v-if="member && member.dealOrderNum > 0"
						>最长逾期天数：{{ member ? member.overdueDayNum : "" }}</el-tag
					> -->
                    <el-tag type="success" v-if="member && member.dealOrderNum > 0"
                        >最长延期天数：{{ member ? member.extendDayNum : '' }}</el-tag
                    >
                </div>
                <div class="rating" v-if="!!detailInfo.store">
                    <TagLvNew
                        :name="
                            scoreProfileData.finalHitGrade ? scoreProfileData.finalHitGrade : 'ZW'
                        "
                    >
                    </TagLvNew>
                    <p>最终评级</p>
                </div>
            </div>
        </div>
        <div class="title">
            <span class="title-style fw700">客户补充资料</span>
        </div>
        <card class="base-info-new special-card">
            <el-row>
                <el-col
                    :span="item.width ? item.width : 8"
                    v-for="(item, index) in personInfoList"
                    :key="index"
                >
                    <span class="label">{{ item.label }}</span>
                    <span class="value">{{
                        item.dec ? scoreProfileData[item.dec] : scoreProfileData[item.prop] || '-'
                    }}</span>
                </el-col>
            </el-row>
        </card>
    </card>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin';
export default {
    mixins: [authBtnMixin],
    name: 'BaseInfo',
    props: {
        detailInfo: {
            type: Object,
            default: () => {},
        },
        member: {
            type: Object,
            default: () => {},
        },
    },
    inject: ['reload'],
    data() {
        return {
            scoreProfileData: {
                orderNo: '',
                sysHitScore: '',
                sysHitSuggestDesc: '',
                sysHitGrade: '',
                finalHitGrade: '',
                zmScore: '',
                creditStatus: '',
                rentHistory: '',
                involveStatus: '',
                inSchoolStatus: '',
                workCompany: '',
                jobCategory: '',
                jobName: '',
                income: '',
            },
            personInfoList: [
                {
                    prop: 'zmScore',
                    label: '支付宝芝麻分：',
                    require: true,
                },
                {
                    prop: 'creditStatus',
                    label: '信用情况：',
                    type: 'member:creditStatus',
                    require: true,
                    dec: 'creditStatusDesc',
                },
                {
                    prop: 'rentHistory',
                    label: '租机历史：',
                    type: 'member:rentHistory',
                    dec: 'rentHistoryDesc',
                    require: true,
                },
                {
                    prop: 'inSchoolStatus',
                    label: '是否学生在读：',
                    type: 'member:inSchoolStatus',
                    dec: 'inSchoolStatusDesc',
                    require: true,
                },
                {
                    prop: 'involveStatus',
                    label: '涉诉情况：',
                    type: 'member:involveStatus',
                    width: 16,
                    dec: 'involveStatusDesc',
                    require: true,
                },
                {
                    prop: 'workCompany',
                    label: '工作单位：',
                },
                {
                    prop: 'jobCategory',
                    label: '岗位：',
                    type: 'member:jobCategory',
                    dec: 'jobCategoryName',
                },
                {
                    prop: 'income',
                    label: '薪资：',
                },
            ],
        };
    },
    computed: {
        orderScoreProfile() {
            return this.detailInfo.orderScoreProfile || {};
        },
    },
    watch: {
        orderScoreProfile(val) {
            if (val) {
                this.scoreProfileData = { ...this.orderScoreProfile, ...val };
            }
        },
    },
    mounted() {},

    methods: {},
};
</script>

<style lang="scss" scoped>
.base-info-new /deep/ {
    border-color: transparent;
    margin-top: 20px;
    width: 100%;
    border-radius: 16px;
    color: #222222;
    .fw700 {
        font-weight: 700;
    }
    .label {
        color: #666666;
        font-size: 14px;
    }
    .ivu-card {
        border-radius: 18px;
    }
    .el-dialog__footer {
        padding-bottom: 30px !important;
    }
    .base-info-top {
        display: flex;
        align-items: flex-start;
        .id-card-pic {
            margin-right: 3%;
        }
        .person-info {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            line-height: 35px;

            .mr50 {
                margin-right: 50px;
            }
            .el-tag {
                height: 25px;
                border-radius: 15px;
                margin-right: 10px;
                line-height: 25px;
            }
            .history-tag {
                .el-tag {
                    height: 28px;
                    line-height: 28px;
                }
            }
            .el-tag.el-tag--success {
                background-color: #fff;
                color: #666666;
                border-color: #e4e4e4;
            }
            .name {
                color: #222222;
                font-size: 18px;
                font-family: 微软雅黑;
                margin-right: 2%;
            }
            .rating {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 16px;
                .edit-btn {
                    font-size: 12px;
                }
            }
        }
    }
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-style {
            color: #333333;
            font-size: 16px;
        }
        .supple-inform-edit {
            cursor: pointer;
            background: #f7f8fa;
            color: #333333;
            padding: 8px 18px;
            border-radius: 45px;
        }
    }

    .tag-ku {
        padding: 4px 12px;
        border-radius: 46px;
        font-size: 12px;
        margin-right: 5px;
        color: #fff;
        .el-icon-warning {
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
    }
    // 黑名单
    .t1 {
        background-color: #d05252;
    }
    .t2 {
        background-color: #fbb438;
    }
    // 白名单
    .t3 {
        background: #4cd2a2;
    }
    .update-final-grade-dialog {
        .el-radio-group {
            display: flex;
            justify-content: space-around;
            margin-bottom: 15px;
        }
        .final-grade-remark {
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            white-space: nowrap;
        }
        .el-dialog__footer {
            padding-top: 0;
        }
    }
}
.special-card {
    background: #f7f8fa;
    line-height: 35px;
    .label {
        display: inline-block;
        min-width: 120px;
        text-align: right;
    }
    .value {
        font-weight: 600;
    }
}
.el-image {
    border-radius: 5px;
}
</style>
