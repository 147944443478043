<template>
	<div class="wrapper">
		<admin-title-new :title="$route.meta.name"></admin-title-new>
		<el-tabs v-model="activeName" class="store-tab">
			<el-tab-pane label="基础信息" name="1"></el-tab-pane>
			<el-tab-pane label="机审信息" name="2"></el-tab-pane>
			<el-tab-pane label="三方数据" name="3"></el-tab-pane>
		</el-tabs>

		<!-- 基础信息 -->
		<DetailBase
			ref="base"
			v-show="activeName == 1"
			@goJS="goJS"
		/>

		<!-- 机审信息 -->
		<DetailJiShen
			ref="riskReview"
			v-show="activeName == 2"
		/>

		<!-- 三方数据 -->
		<DetailRisk
        ref="risk"
        v-show="activeName == 3"
    />
	</div>
</template>

<script>
import DetailBase from "./detail-base.vue";
import DetailJiShen from "./detail-jishen.vue";
import DetailRisk from "./detail-risk.vue";

export default {
	name: "Detail",
	components: {
		DetailRisk,
		DetailBase,
		DetailJiShen,
	},
	data() {
		return {
			activeName: "1",
		};
	},
	beforeRouteLeave(to, from, next) {
		if (to.name == "OrderUpdateGoods") {
			this.$refs.base.flag = false;
		}
		next();
	},
	mounted() {},
	methods: {
		goJS() {
			this.activeName = "2";
		},
	},
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
	width: 100%;
	min-width: 1150px;

	.title {
		font-weight: 600;
		margin: 10px 0;
		&:first-child {
			margin-top: 0;
		}
	}
	.store-tab {
		.el-tabs__nav {
			height: 48px;
		}
		.el-tabs__header {
			margin-bottom: 0;
			.el-tabs__nav-wrap::after {
				display: none;
			}
			.el-tabs__item {
				font-size: 20px;
				color: #666666;
			}
			.el-tabs__item.is-active {
				color: #222222;
			}
			.el-tabs__active-bar {
				width: 43px !important;
				height: 4px;
				border-radius: 39px;
				left: 20px;
			}
		}
	}
}
.without-layout {
	.wrapper {
		height: 100% !important;
		overflow-y: hidden;
		display: flex;
		flex-direction: column;
	}
	.el-tabs {
		padding: 0 14% !important;
	}
}
</style>
