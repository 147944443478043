<template>
    <card class="contact-wrapper">
        <el-row :gutter="20">
            <!-- 紧急联系人 -->
            <el-col v-if="type == 1" :span="24">
                <AdminTitle title="紧急联系人" size="20px">
                    <div class="content-col-title">
                        <div class="show-more" @click="showContact">查看更多</div>
                        <el-button type="primary" class="add-more" size="mini" @click="addContact"
                            >新增</el-button
                        >
                    </div>
                </AdminTitle>
                <tp-table
                    :showPagination="false"
                    :tableData="orderInfo.memberRelationList"
                    :columns="memberRelationColumns"
                />
            </el-col>
            <!-- 订单备注 -->
            <el-col v-if="type == 2" :span="24">
                <AdminTitle title="订单备注" size="20px">
                    <div class="content-col-title">
                        <div class="show-more" @click="showRemark">查看更多</div>
                    </div>
                </AdminTitle>
                <tp-table
                    :showPagination="false"
                    :tableData="orderInfo.orderTraceList"
                    :columns="orderTraceColumns"
                />
                <div class="content-col-trace">
                    <el-input
                        class="content-remark-input"
                        v-model="orderTraceContent"
                        size="small"
                    ></el-input>
                    <el-button
                        class="add-new"
                        size="small"
                        @click="saveOrderTraceContent"
                        icon="el-icon-plus"
                        >新增</el-button
                    >
                </div>
            </el-col>
        </el-row>
        <!-- 联系人列表 -->
        <el-dialog title="紧急联系人" :visible.sync="outerVisibleContact" width="60%">
            <!-- 添加联系人 -->
            <div class="contact-remark-table">
                <div class="contact-add">
                    <el-button class="round-button" type="info" size="mini" @click="copyRelation"
                        >复制</el-button
                    >
                    <el-button class="round-button" type="primary" size="mini" @click="addRelation"
                        >添加</el-button
                    >
                </div>
                <tp-table
                    :height="500"
                    :showPagination="false"
                    :tableData="userList"
                    :columns="userColumns"
                />
            </div>
        </el-dialog>
        <el-dialog
            width="450px"
            title="添加、编辑联系人"
            :visible.sync="innerVisibleContact"
            append-to-body
        >
            <el-form ref="formUser" :model="userFormData" label-width="80px">
                <el-form-item label="联系人" prop="relationName" :rules="rules">
                    <el-input
                        style="width: 300px"
                        v-model="userFormData.relationName"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="relationMobile" :rules="rules">
                    <el-input
                        style="width: 300px"
                        v-model="userFormData.relationMobile"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="关系类型" prop="relationType" :rules="rules">
                    <SelectDict
                        v-model="userFormData.relationType"
                        dictCode="member:relationType"
                        width="300px"
                    ></SelectDict>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        style="width: 300px"
                        type="textarea"
                        v-model="userFormData.remark"
                    ></el-input>
                </el-form-item>
                <div style="text-align: center">
                    <el-button @click="innerVisibleContact = false" size="small">取 消</el-button>
                    <el-button type="primary" @click="saveRelation" size="small">提 交</el-button>
                </div>
            </el-form>
        </el-dialog>
        <el-dialog width="450px" title="添加联系人" :visible.sync="addVisibleContact">
            <el-form ref="formUser" :model="userFormData" label-width="80px">
                <el-form-item label="联系人" prop="relationName" :rules="rules">
                    <el-input
                        style="width: 300px"
                        v-model="userFormData.relationName"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="relationMobile" :rules="rules">
                    <el-input
                        style="width: 300px"
                        v-model="userFormData.relationMobile"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="关系类型" prop="relationType" :rules="rules">
                    <SelectDict
                        v-model="userFormData.relationType"
                        dictCode="member:relationType"
                        width="300px"
                    ></SelectDict>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        style="width: 300px"
                        type="textarea"
                        v-model="userFormData.remark"
                    ></el-input>
                </el-form-item>
                <div style="text-align: center">
                    <el-button @click="addVisibleContact = false" size="small">取 消</el-button>
                    <el-button type="primary" @click="saveRelation" size="small">提 交</el-button>
                </div>
            </el-form>
        </el-dialog>

        <!-- 备注列表 -->
        <el-dialog title="备注列表" :visible.sync="dialogVisibleRemark" width="60%">
            <div class="contact-remark-table">
                <div class="remark-content">
                    <div class="remark-input">
                        <el-input
                            v-model="remarkData.content"
                            size="small"
                            clearable
                            placeholder="请输入"
                        ></el-input>
                    </div>
                    <div class="remark-submit">
                        <el-button type="primary" size="mini" @click="handleAddRemark"
                            >发送</el-button
                        >
                    </div>
                </div>
                <tp-table
                    :height=500
                    :showPagination="true"
                    :tableData="remarkList"
                    :columns="remarkColumns"
                    :totalNum="remarkTotal"
                    :current-page.sync="currentPageRemark"
                    :pageSize.sync="pageSizeRemark"
                />
            </div>
        </el-dialog>
    </card>
</template>

<script>
import {
    getRemarkListAPI,
    handleAddRemarkAPI,
    getRelationListAPI,
    addRelationAPI,
    editRelationAPI,
    getRelationDetailAPI,
    delRelationAPI,
    riskRelationAPI,
} from './api';
import SelectDict from '@/views/components/select-dict.vue';

const memberRelationColumns = [
    {
        label: '姓名',
        prop: 'relationName',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['relationName']}</p>;
        },
    },
    {
        label: '电话',
        prop: 'relationMobile',
        minWidth: '130',
        customRender(h, row) {
            return <p>{row['relationMobile']}</p>;
        },
    },
    {
        label: '关系',
        prop: 'relationTypeName',
        minWidth: '150',
        customRender(h, row) {
            return <div>{row['relationTypeName']}</div>;
        },
    },
    {
        label: '备注',
        prop: 'remark',
        minWidth: '150',
        customRender(h, row) {
            return <p>{row['remark']}</p>;
        },
    },
];

const orderTraceColumns = [
    {
        label: '时间',
        prop: 'createTime',
        minWidth: '180',
        customRender(h, row) {
            return <p>{row['createTime']}</p>;
        },
    },
    {
        label: '审核人',
        prop: 'traceUserName',
        minWidth: '180',
        customRender(h, row) {
            return <p>{row['traceUserName']}</p>;
        },
    },
    {
        label: '备注',
        prop: 'content',
        width: '200',
        fixed: 'right',
        customRender(h, row) {
            return <p>{row['content']}</p>;
        },
    },
];

const userColumns = [
    {
        label: 'id',
        prop: 'id',
        minWidth: '100',
        customRender(h, row) {
            return <p>{row['id']}</p>;
        },
    },
    {
        label: '姓名',
        prop: 'relationName',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['relationName']}</p>;
        },
    },
    {
        label: '电话',
        prop: 'relationMobile',
        minWidth: '130',
        customRender(h, row) {
            return <p>{row['relationMobile']}</p>;
        },
    },
    {
        label: '关系',
        prop: 'relationTypeName',
        minWidth: '180',
        customRender(h, row) {
            return <div>{row['relationTypeName']}</div>;
        },
    },
    {
        label: '备注',
        prop: 'remark',
        minWidth: '150',
        customRender(h, row) {
            return <p>{row['remark']}</p>;
        },
    },
    {
        label: '操作',
        minWidth: '90',
        customRender(h, row) {
            return (
                <div>
                    <el-link
                        style="margin-right:10px;"
                        underline={false}
                        type="primary"
                        onClick={() => this.editRelation(row['id'])}
                    >
                        编辑
                    </el-link>
                    <el-link
                        underline={false}
                        type="primary"
                        onClick={() => this.delRelation(row['id'])}
                    >
                        删除
                    </el-link>
                </div>
            );
        },
    },
];

const remarkColumns = [
    {
        label: '时间',
        prop: 'createTime',
        minWidth: '180',
        customRender(h, row) {
            return <p>{row['createTime']}</p>;
        },
    },
    {
        label: '审核人',
        prop: 'identitraceUsertyNo',
        minWidth: '180',
        customRender(h, row) {
            return <p>{row['traceUser']?.['userName']}</p>;
        },
    },
    {
        label: '备注',
        prop: 'content',
        width: '200',
        fixed: 'right',
        customRender(h, row) {
            return <p>{row['content']}</p>;
        },
    },
];

export default {
    name: 'Contact',
    props: ['member', 'orderInfo', 'type'],
    components: { SelectDict },
    data() {
        return {
            rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
            memberRelationColumns,
            orderTraceColumns,
            userColumns,
            remarkColumns,
            // 编辑联系人相关
            outerVisibleContact: false,
            innerVisibleContact: false,
            addVisibleContact: false,
            userFormData: {
                relationName: '',
                relationMobile: '',
                relationType: '',
                remark: '',
            },
            userList: [],
            dialogVisibleRemark: false,
            remarkList: [],
            remarkData: {
                content: '',
                orderNo: this.$route.params.id,
            },
            currentPageRemark: 1,
            pageSizeRemark: 10,
            remarkTotal: 0,
            orderTraceContent: '',
        };
    },
    provide() {
        return {
            context: this,
        };
    },
    watch: {
        currentPageRemark() {
            this.getRemarkList();
        },
        pageSizeRemark() {
            this.getRemarkList();
        },
    },
    mounted() {},

    methods: {
        async getRemarkList() {
            let params = {
                orderNo: this.$route.params.id,
                page: this.currentPageRemark,
                pageSize: this.pageSizeRemark,
            };
            const res = await getRemarkListAPI(params);
            this.remarkList = res.list || [];
            this.remarkTotal = res.total || 0;
        },
        // 查看备注
        showRemark() {
            this.dialogVisibleRemark = true;
            this.remarkData = {
                content: '',
                orderNo: this.$route.params.id,
            };
            this.getRemarkList();
        },
        //  添加备注
        handleAddRemark() {
            handleAddRemarkAPI(this.remarkData).then(() => {
                this.$message.success('发送成功');
                this.getRemarkList();
                this.remarkData.content = '';
                this.$emit('update:flag', true);
                this.$emit('updateDetail');
            });
        },
        saveOrderTraceContent() {
            let param = {
                content: this.orderTraceContent,
                orderNo: this.$route.params.id,
            };
            handleAddRemarkAPI(param).then(() => {
                this.$message.success('保存成功');
                this.orderTraceContent = '';
                this.$emit('update:flag', true);
                this.$emit('updateDetail');
            });
        },
        // 查看联系人
        showContact() {
            this.outerVisibleContact = true;
            this.getRelationList();
        },
        // 联系人列表
        async getRelationList() {
            this.userList = await getRelationListAPI(this.member.id);
        },
        // 打开添加弹窗
        addRelation() {
            this.innerVisibleContact = true;
            this.userFormData = {
                relationName: '',
                relationMobile: '',
                relationType: '',
                remark: '',
                memberId: this.member.id,
            };
        },
        // 添加联系人
        async saveRelation() {
            const { id, relationMobile } = this.userFormData;
            const { riskAlertList } = await riskRelationAPI({ id, relationMobile });
            if (riskAlertList.length == 0) return this.submit();

            this.$confirm('<div>' + riskAlertList.join('<br>') + '</div>', '提示', {
                confirmButtonText: '继续提交',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true,
                type: 'warning',
            })
                .then(() => {
                    this.submit();
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消保存',
                    });
                });
        },
        // 提交
        submit() {
            if (this.userFormData.id) {
                editRelationAPI(this.userFormData).then(() => {
                    this.$message.success('编辑成功');
                    this.innerVisibleContact = false;
                    this.getRelationList();
                    this.$emit('update:flag', true);
                    this.$emit('updateDetail');
                });
            } else {
                addRelationAPI(this.userFormData).then(() => {
                    this.$message.success('添加成功');
                    this.innerVisibleContact = false;
                    this.addVisibleContact = false;
                    this.getRelationList();
                    this.$emit('update:flag', true);
                    this.$emit('updateDetail');
                });
            }
        },
        // 编辑联系人
        editRelation(id) {
            this.innerVisibleContact = true;
            this.getRelationDetail(id);
        },
        addContact() {
            this.addVisibleContact = true;
            this.userFormData = {
                relationName: '',
                relationMobile: '',
                relationType: '',
                remark: '',
                memberId: this.member.id,
            };
        },
        async getRelationDetail(id) {
            this.userFormData = await getRelationDetailAPI(id);
        },
        delRelation(id) {
            this.$confirm('此操作将永久删除该联系人, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    delRelationAPI(id).then(() => {
                        this.getRelationList();
                        this.$emit('update:flag', true);
                        this.$emit('updateDetail');
                        this.$message({
                            type: 'success',
                            message: '删除成功!',
                        });
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        // 复制
        copyRelation() {
            const { userList } = this;
            let str = '';
            userList.forEach((item) => {
                str =
                    str +
                    item.relationName +
                    '，' +
                    item.relationMobile +
                    '，' +
                    item.relationTypeName +
                    '，' +
                    item.remark +
                    '\n';
            });
            this.$copyText(str).then(
                () => {
                    this.$message.success('复制成功');
                },
                () => {
                    this.$message.error('复制失败');
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.contact-wrapper /deep/ {
    width: 100%;
    border-color: transparent;
    margin-top: 20px;
    border-radius: 16px;
    color: #222222;

    .el-dialog__body {
        padding-top: 10px;
    }
    .contact-remark {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .item {
            background: #fafafa;
            flex: 1;
            border: 1px solid #ebeef5;
            padding: 5px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:first-child {
                margin-right: 20px;
            }
        }
    }
    .contact-remark-table {
        .tp-table {
            .el-table__fixed-right {
                height: 100% !important;
                &::before {
                  display: none;
                }
            }
        }
        .remark-content {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .remark-input {
                flex: 1;
                margin-right: 20px;
            }
        }
        .contact-add {
            text-align: right;
            margin-bottom: 20px;
        }
    }
    .el-dialog__body {
        padding: 30px;
    }
    .content-col-title {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 5px;
    }
    .content-col-trace {
        padding-top: 10px;
        line-height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .content-remark-input {
            width: 30%;
            margin-right: 20px;
            .el-input__inner {
                border: none;
                background: #f7f8fa;
            }
        }
    }
    .add-more {
        margin-left: 15px;
        border-radius: 15px;
        font-size: 12px;
    }
    .show-more {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 15px;
        color: #333333;
        padding: 6px 14px;
        font-size: 12px;
        background: #f7f8fa;
    }
}
.round-button {
    border-radius: 15px;
}
.add-new {
    background: #409EFF;
    color: #fff;
    border: none;
}
.without-layout .contact-wrapper /deep/ {
    .el-table__header .el-table__cell {
        font-weight: 600;
    }
}
</style>
