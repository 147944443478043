<template>
    <!-- 发货信息 -->
    <card class="wrapper">
        <AdminTitle title="发货信息" size="20px"></AdminTitle>
        <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="serialNo" label="设备串号"></el-table-column>
            <el-table-column prop="costPrice" label="采购价"></el-table-column>
            <el-table-column prop="deliveryNo" label="快递单号"></el-table-column>
            <el-table-column prop="deliveryCompanyName" label="快递公司"> </el-table-column>
            <el-table-column prop="deliveryTime" label="发货时间"> </el-table-column>
            <el-table-column prop="receiveTime" label="签收时间"> </el-table-column>
            <el-table-column
                prop="lastTraceNode"
                label="物流信息"
                v-if="orderDelivery.deliveryNo && orderDelivery.deliveryCompanyName"
            >
                <template slot-scope="{ row }">
                    {{ row.lastTraceNode ? row.lastTraceNode.statusName : '暂无数据' }}
                    <el-button
                        v-checkbtn="isShowBtn(AUTH_BTN.order_list_delivery_detail)"
                        type="primary"
                        size="mini"
                        @click="showDeliveryDetail"
                        >详情</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="deliveryImageUrlList" label="发货图片">
          <template slot-scope="{ row }">
            <el-image
                v-show="row.deliveryImageUrlList && row.deliveryImageUrlList.length"
                style="width: 60px; height: 60px; margin-right: 10px; border-radius: 5px"
                v-for="(item, i) in row.deliveryImageUrlList"
                :key="i"
                :src="item"
                :preview-src-list="[item]"
            >
            </el-image>
            <span v-show="!row.deliveryImageUrlList || !row.deliveryImageUrlList.length"
            >暂无数据</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="receiveImageUrlList" label="签收证明">
          <template slot-scope="{ row }">
            <el-image
                v-show="row.receiveImageUrlList && row.receiveImageUrlList.length"
                style="width: 60px; height: 60px; margin-right: 10px; border-radius: 5px"
                v-for="(item, i) in row.receiveImageUrlList"
                :key="i"
                :src="item"
                :preview-src-list="[item]"
            >
            </el-image>
            <span v-show="!row.receiveImageUrlList || !row.receiveImageUrlList.length"
            >暂无数据</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="200">
          <template slot-scope="{ row }">
            {{ row.remark || '暂无数据' }}
          </template>
        </el-table-column>
      </el-table>
        <!-- 物流信息 -->
        <el-drawer
            class="delivery-box"
            title="物流详情"
            :show-close="true"
            :withHeader="true"
            size="60%"
            :visible.sync="drawer"
            direction="rtl"
        >
            <div class="delivery-detail">
                <admin-title title="收件人信息"></admin-title>
                <el-descriptions direction="vertical" :column="5" border>
                    <el-descriptions-item label="收件人">{{
                        deliveryDetail.contactName
                    }}</el-descriptions-item>
                    <el-descriptions-item label="联系方式">{{
                        deliveryDetail.contactMobile
                    }}</el-descriptions-item>
                    <el-descriptions-item label="快递公司" :span="1">{{
                        deliveryDetail.deliveryCompanyName
                    }}</el-descriptions-item>
                    <el-descriptions-item label="快递单号">{{
                        deliveryDetail.deliveryNo
                    }}</el-descriptions-item>
                    <el-descriptions-item label="收货地址">{{
                        deliveryDetail.contactAddress
                    }}</el-descriptions-item>
                </el-descriptions>
                <admin-title title="物流信息"></admin-title>
                <div class="delivery-list" v-if="deliveryDetail.nodeList.length">
                    <div class="item" v-for="(item, i) in deliveryDetail.nodeList" :key="i">
                        <div :class="['circle', i == 0 ? 'green' : '']">
                            <i class="el-icon-check"></i>
                        </div>
                        <div :class="['dsc', i == 0 ? 'first' : '']">
                            <div class="status">{{ item.statusName }}</div>
                            <div class="content">{{ item.content }}</div>
                            <div class="time">{{ item.time }}</div>
                        </div>
                    </div>
                </div>
                <div class="delivery-list" v-else>暂无数据</div>
            </div>
        </el-drawer>
    </card>
</template>

<script>
import { getDeliveryDetailAPI } from './api';
import { authBtnMixin } from '@/mixins/authBtnMixin';
export default {
    name: 'Address',
    mixins: [authBtnMixin],
    props: {
        orderDelivery: {
            type: Object,
            default: () => {},
        },
        detail: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            drawer: false,
            deliveryDetail: {
                nodeList: [],
            },
        };
    },
    watch: {
        orderDelivery: {
            handler(newV, oldV) {
                this.address = { ...this.orderDelivery };
            },
        },
    },
    computed: {
        tableData() {
            return [
                {
                    costPrice: this.detail.costPrice || '-',
                    serialNo: this.orderDelivery.serialNo || '-',
                    deliveryNo: this.orderDelivery.deliveryNo || '-',
                    deliveryCompanyName: this.orderDelivery.deliveryCompanyName || '-',
                    deliveryTime: this.detail.deliveryTime || '-',
                    receiveTime: this.detail.receiveTime || '-',
                    deliveryImageUrlList: this.orderDelivery.deliveryImageUrlList || [],
                    receiveImageUrlList: this.orderDelivery.receiveImageUrlList || [],
                    remark: this.orderDelivery.remark || '-',
                    lastTraceNode: this.orderDelivery.lastTraceNode
                        ? this.orderDelivery.lastTraceNode.statusName
                        : '-',
                },
            ];
        },
    },
    mounted() {},

    methods: {
        // 编辑联系人
        editUser() {
            this.dialogVisibleUser = true;
        },
        // 保存工作信息
        saveJob() {
            this.$confirm('工作单位未填写，您确定要提交吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作',
                    });
                });
        },
        async showDeliveryDetail() {
            this.deliveryDetail = await getDeliveryDetailAPI(this.$route.params.id);
            this.drawer = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
    width: 100%;
    border-color: transparent;
    margin-top: 20px;
    border-radius: 16px;
    color: #222222;
    .el-button {
        border-radius: 15px;
    }
}
.without-layout .wrapper /deep/ {
    .el-table__header .el-table__cell {
        font-weight: 600;
        color: #1a1e25;
    }
}
.delivery-box {
  .el-drawer__header {
    padding-bottom: 0;
    margin-bottom: 0;
    & > span {
      font-weight: 600;
      font-size: 17px;
    }
  }
  .delivery-detail {
    padding: 20px;
    padding-top: 0;

    .delivery-list {
      padding: 20px;
      max-height: calc(100vh - 330px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 3px;
        background-color: #ccc;
      }
      .item {
        position: relative;
        min-height: 100px;
        border-left: 4px solid #ccc;

        padding: 20px;
        padding-top: 0;
        &:last-child {
          border-left: none;
        }
        .circle {
          position: absolute;
          top: 0;
          left: 0;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: #666;
          transform: translate(-53%, -10%);
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            color: #fff;
            font-weight: 600;
          }
        }
        .green {
          background: #66b1ff;
        }
        .dsc {
          color: #333;
          // .status {
          // }
          .content {
            margin: 10px 0;
          }
          .time {
            color: #666666;
          }
        }
        .first {
          color: #66b1ff;
          .time {
            color: #66b1ff;
          }
        }
      }
    }
  }
}
</style>
